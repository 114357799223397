.h-do-not-print {
    display: none !important;
}

* {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important; /* Black prints faster: h5bp.com/s */
    text-shadow: none !important;
}

a,
a:visited {
    text-decoration: underline;
}

abbr[title]::after {
    content: ' (' attr(title) ')';
}

pre,
blockquote {
    border: .1rem solid #999999;
    page-break-inside: avoid;
}

thead {
    display: table-header-group; /* h5bp.com/t */
}

tr,
img {
    page-break-inside: avoid;
}

img {
    max-width: 100% !important;
}

@page {
    margin: .5cm;
}

p,
h2,
h3 {
    orphans: 3;
    widows: 3;
}

h2,
h3 {
    page-break-after: avoid;
}

article img {
    margin-bottom: 1rem;
}
